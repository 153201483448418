import React from 'react';
import { graphql, HeadFC, Link } from 'gatsby';
import FooterLinks from '../components/FooterLinks';
import Header from '../components/Header';

type Props = {
  data: {
    markdownRemark: {
      frontmatter: any;
      html: string;
      fields: {
        readingTime: {
          text: string;
        };
      };
    };
  };
};

const Template = ({
  data: {
    markdownRemark: {
      frontmatter,
      html,
      fields: {
        readingTime: { text },
      },
    },
  },
}: Props) => (
  <main>
    <Header title={frontmatter.title} />
    <div className="intro">
      <h1>{frontmatter.title}</h1>
      <p className="date">{frontmatter.date}</p>
      <p className="reading">{text}</p>
      <Link to="/">Back</Link>
    </div>
    <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
    <FooterLinks />
  </main>
);

export default Template;

export const Head: HeadFC = ({
  data: {
    markdownRemark: { frontmatter },
  },
}: Props) => <Header title={frontmatter.title} />;

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`;
